import { LLMActionTypes } from "./actionType";
import * as API from "../../../api/llmAPI";

export const createLogSummary = (entityId, body) => (dispatch) =>
  dispatch({
    type: LLMActionTypes.CREATE_LOG_SUMMARY,
    payload: API.createLogSummary(entityId, body)
  })

export const clearLogSummary = () => (dispatch) =>
  dispatch({
    type: LLMActionTypes.CLEAR_LOG_SUMMARY
  })

export const sendAgentMessage = (entityId, body) => (dispatch) =>
  dispatch({
    type: LLMActionTypes.SEND_AGENT_MESSAGE,
    payload: API.sendAgentMessage(entityId, body)
  })