import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import {
  HighlightOff,
  VisibilityOffOutlined,
  VisibilityOutlined
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Input,
  Select
} from "../../components/shared/ui-components/form-components";
import {
  createUser,
  deleteUser,
  getUsers,
  updateUser
} from "../../redux/actions/UserActions";
import * as commonService from "../../utility/CommonService";
import SimpleReactValidator from "simple-react-validator";
import { ROLE } from "../../config";
import timezones from "google-timezones-json";
import { clearUsers } from "../../redux/actions/UserActions/Action";
import { ROLES } from "../../utility/constants/constants";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  getActiveUserCounts,
  getOrganization,
  getOrganizations
} from "../../redux/actions/OrganizationActions/Action";
import swal from "sweetalert";
import {
  UserPaginationContext
} from "../../components/Admin/AdminNavContainer";
import {
  clearCluster,
  clearClusters,
  getCluster,
  getClusters
} from "../../redux/actions/ClusterActions";
import { handleLoadOptions } from "../../utility/CommonService";
import { enable2FA } from "../../redux/actions/AuthActions";

const EditUserModal = ({
                         showModal,
                         setShowModal,
                         user,
                         selectedOrg
                       }) => {
  const dispatch = useDispatch();
  const { handleClearTerm } = useContext(UserPaginationContext);
  const simpleValidator = useRef(new SimpleReactValidator());
  const currentUser = useSelector((state) => state.authReducer.user);
  const { configOptions } = useSelector((state) => state.flightingReducer);
  const {
    organization,
    organizations,
    orgCount
  } = useSelector((state) => state.organizationReducer);
  const {
    cluster,
    clusters,
    clusterCount
  } = useSelector((state) => state.clusterReducer);
  const [isDisable, setDisable] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "",
    timezone: "",
    organization: "",
    cluster_id: "",
  });
  const roles = configOptions.user_roles
    ? configOptions.user_roles.filter(
      (e) =>
        e !== "superadmin" || currentUser?.role === "superadmin"
    )
    : ROLE;
  const [orgPagination, setOrgPagination] = useState({
    page: 1,
    limit: 10,
  });
  // Pagination for the clusters dropdown
  const [clustersPagination, setClustersPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleDelete = (uid) => {
    swal({
      title: "",
      text: "Are you sure?",
      className: "logout-alert-modal",
      buttons: true,
    }).then(async (result) => {
      if (result) {
        const res = await dispatch(deleteUser(uid))
        if (res.value) {
          await dispatch(
            clearUsers()
          );
          commonService.forSuccess("User Deleted", "Success");
          handleClose()
          await dispatch(
            getUsers({ page: 1, limit: 10, organization: form.organization })
          )
          await dispatch(getActiveUserCounts())
        }
      }
    })
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    if (isDisable) setDisable(false);
  };


  const handle2FAChange = async (checked, userId) => {
    return await dispatch(
      enable2FA({
        body: {
          enabled_2fa: checked
        },
        id: userId
      })
    )
  }

  const cleanup2FAChange = async (checked, userId) => {
    await handle2FAChange(checked, userId)
    handleClose()
  }

  const handleClose = () => {
    setShowModal(false);
    dispatch(clearCluster());
    dispatch(clearClusters());
    dispatch(clearUsers());
    handleClearTerm(true);
    setOrgPagination({
      page: 1,
      limit: 10
    });
    setClustersPagination({
      page: 1,
      limit: 10
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let res;
      if (currentUser.role === ROLES.ADMINISTRATOR) {
        form.organization = currentUser.organization
      }

      if (user) {
        res = await dispatch(
          updateUser({
            body: {
              user: form,
            },
            id: user.uid,
          })
        );
      } else {
        res = await dispatch(
          createUser({
            body: {
              user: form,
            },
          })
        );
      }

      if (res?.value.success) {
        await dispatch(
          clearUsers()
        )
        commonService.forSuccess(
          user ? "User Updated successfully!" : " User created successfully!",
          "Success"
        );
        handleClose()
        await dispatch(getActiveUserCounts())
      }
    } else {
      simpleValidator.current.showMessages(); //show validation messages
      setDisable(true);
    }
  };

  useEffect(() => {
    if (showModal && currentUser.role === ROLES.SUPERADMIN) {
      dispatch(
        getOrganizations({ ...orgPagination })
      );
      dispatch(getOrganization({ id: selectedOrg }));
    }
  }, [dispatch, currentUser.role, orgPagination, selectedOrg, showModal]);

  useEffect(() => {
    if (showModal && currentUser.role === ROLES.SUPERADMIN) {
      dispatch(
        getClusters({ ...clustersPagination })
      );
    }
  }, [clustersPagination, currentUser.role, dispatch, showModal])

  useEffect(() => {
    if (showModal && user) {
      dispatch(
        getCluster(user.cluster_id)
      );
    }
  }, [dispatch, showModal, user])

  useEffect(() => {
    setForm({
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      password: user?.password,
      role: user?.role,
      timezone: user?.timezone,
      organization: user?.organization,
      cluster_id: user?.cluster_id,
    })
  }, [user])

  useEffect(() => {
    if (form.cluster_id) {
      dispatch(getCluster(form.cluster_id))
    }
  }, [dispatch, form.cluster_id])

  return (
    <Modal show={showModal}
           onHide={handleClose}
           animation={false}>
      <Modal.Header className="border-0 modal_header">
        <div className="header_text">Edit user</div>
        <IconButton onClick={handleClose} aria-label="Close">
          <HighlightOff></HighlightOff>
        </IconButton>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="edit_field_wrapper">
            <div className="edit_field_label">
              Organization
            </div>
            <AsyncPaginate
              placeholder={organization?.name}
              name="organization"
              onChange={(e) => {
                handleChange({
                  target: { value: e.value, name: "organization" },
                })
              }}
              className="edit_select"
              isDisabled={currentUser.role !== ROLES.SUPERADMIN}
              loadOptions={handleLoadOptions(organizations, orgPagination, setOrgPagination, orgCount)}
            />
            {simpleValidator.current.message(
              "organization",
              form.organization,
              "required"
            )}
          </div>
          <div className="edit_field_wrapper">
            <>
              <div className="edit_field_label">
                Cluster
              </div>
              <AsyncPaginate
                placeholder={cluster ? (cluster.name ? `${cluster.name}` : `${cluster.id}`) : "select the cluster"}
                name="cluster"
                onChange={(e) => {
                  handleChange({
                    target: { value: e.value, name: "cluster_id" },
                  })
                }}
                className="edit_select"
                isDisabled={currentUser.role !== ROLES.SUPERADMIN}
                loadOptions={handleLoadOptions(clusters, clustersPagination, setClustersPagination, clusterCount)}
              />
            </>
            {simpleValidator.current.message(
              "cluster_id",
              form.cluster_id,
              "required"
            )}
          </div>
          <div className="edit_field_wrapper">
            <div className="edit_field_label">
              First Name
            </div>
            <Input
              type="text"
              name="first_name"
              placeholder="enter first name"
              value={form.first_name}
              onChange={handleChange}
              className="edit_field"
            />
            {simpleValidator.current.message(
              "first_name",
              form.first_name,
              "required"
            )}
          </div>
          <div className="edit_field_wrapper">
            <div className="edit_field_label">
              Last Name
            </div>
            <Input
              type="text"
              name="last_name"
              placeholder="enter last name"
              value={form.last_name}
              onChange={handleChange}
              className="edit_field"
            />
            {simpleValidator.current.message(
              "last_name",
              form.last_name,
              "required"
            )}
          </div>
          <div className="edit_field_wrapper">
            <div className="edit_field_label">
              Email
            </div>
            <Input
              type="text"
              name="email"
              placeholder="enter email address"
              onChange={handleChange}
              value={form.email}
              className="edit_field"
            />{" "}
            {simpleValidator.current.message(
              "email",
              form.email,
              "required|email"
            )}
          </div>
          <div className="edit_field_wrapper">
            <div className="edit_field_label">
              Role
            </div>
            <Select
              placeholder="select role"
              name="role"
              options={roles}
              onChange={(e) =>
                handleChange({
                  target: { value: e.value, name: "role" },
                })
              }
              value={{
                label: form.role,
                value: form.role,
              }}
              className="edit_select"
              required
            />
            {simpleValidator.current.message("role", form.role, "required")}
          </div>
          <div className="edit_field_wrapper">
            <div className="edit_field_label">
              Time Zone
            </div>
            <Select
              options={Object.keys(timezones)}
              placeholder="select the time zone"
              name="timezone"
              onChange={(e) =>
                handleChange({
                  target: { value: e.value, name: "timezone" },
                })
              }
              value={{
                label: form.timezone,
                value: form.timezone,
              }}
              className="edit_select"
              required
            />
            {simpleValidator.current.message(
              "timezone",
              form.timezone,
              "required"
            )}
          </div>
          <div className="edit_field_wrapper">
            <div className="edit_field_label">
              Password
            </div>
            <div className="edit_user_password_field">
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="enter password"
                name="password"
                value={form.password}
                onChange={handleChange}
                className="edit_user_password_field_input"
              />
              <div className="password_field_eye_icon"
                   onClick={togglePasswordVisibility}>
                {showPassword ? <VisibilityOutlined></VisibilityOutlined> :
                  <VisibilityOffOutlined></VisibilityOffOutlined>}
              </div>
            </div>
          </div>
          {
            user?.enabled_2fa && (
              <div className="edit_field_wrapper">
                <div className="edit_field_label">
                  2FA
                </div>
                <div className="edit_select">
                  <>
                    <div className="enable_two_fa_link" onClick={async (e) => {
                      e.preventDefault();
                      await cleanup2FAChange(false, user.uid, organization);
                    }}>
                      Disable 2FA
                    </div>
                  </>
                </div>
              </div>
            )
          }
        </Modal.Body>
      </form>
      <Modal.Footer className="border-0">
        <div className="edit_user_button_container">
          <Button className="edit_user_delete_button btn"
                  onClick={() => handleDelete(user.uid)}>
            Delete
          </Button>
          <Button className="btn" type="submit" onClick={(e) => handleSubmit(e)}
                  disabled={isDisable}>
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default EditUserModal