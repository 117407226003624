import React, { useContext, useEffect, useState } from "react";
import * as commonService from "utility/CommonService.js";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import moment from "moment-timezone";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  DEFAULT_DATETIME_OPTION, FAILURE_COLOR,
  SUCCESS_COLOR
} from "../../../utility/constants/constants";
import {
  clearUsers,
  deleteUser, getUsers
} from "../../../redux/actions/UserActions/Action";
import style from "./users.module.css";
import { Check, Close } from "@mui/icons-material";
import EditUserModal from "../../../screens/Users/EditUserModal";
import {
  getActiveUserCounts
} from "../../../redux/actions/OrganizationActions/Action";
import { getOrganization } from "../../../redux/actions/OrganizationActions/Action";
import {
  UserPaginationContext
} from "../../Admin/AdminNavContainer";
import Loader from "../../shared/Loader/Loader";
import SearchInput from "../../shared/Search/SearchInput";

const UsersTable = ({
                      users,
                      userCount,
                      organizationId
                    }) => {
  const dispatch = useDispatch();
  const { userPagination, handleUserPagination, clearTerm, handleClearTerm } = useContext(UserPaginationContext);
  const { user } = useSelector((state) => state.authReducer);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // This is used as the load state for when infinite scroll needs to load more data
  const [isPending, setIsPending] = useState(false); // This is used to account for the debounce from searching

  useEffect(() => {
    if (organizationId.length > 0) {
      dispatch(
        getUsers({ ...userPagination, organization: organizationId})
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userPagination])

  const handleOpen = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  }

  const handleDelete = (uid) => {
    swal({
      title: "",
      text: "Are you sure?",
      className: "logout-alert-modal",
      buttons: true,
    }).then(async (result) => {
      if (result) {
        const res = await dispatch(deleteUser(uid))
        if (res.value) {
          await dispatch(
            clearUsers()
          );
          commonService.forSuccess("User Deleted", "Success");
          handleClearTerm(true);
          await dispatch(
            getOrganization({ id: organizationId })
          );
          await dispatch(getActiveUserCounts());
        }
      }
    })
  };

  const handlePending = (pendingStatus) => {
    setIsPending(pendingStatus)
  }

  return (
    <>
      <SearchInput handleSearchTerm={handleUserPagination}
                   handlePending={handlePending}
                   clearData={clearUsers()}
                   clearTerm={clearTerm}
                   handleClearTerm={handleClearTerm} />
      <div id="scrollableDiv" className="infinite_scroll_container">
        <InfiniteScroll
          dataLength={users.length}
          next={() => {
            setIsLoading(true);
            handleUserPagination({ ...userPagination, page: userPagination.page + 1 })
          }}
          hasMore={users.length < parseInt(userCount)}
          loader={<Loader loading={isLoading} />}
          scrollableTarget="scrollableDiv"
        >
          {
            isPending ? (
              <table className="ludis_table">
                <thead>
                <tr className="ludis_table_row">
                  <th className="ludis_table_header_cell">Searching...</th>
                </tr>
                </thead>
              </table>
            ) : (
              <>
                { userCount === 0 && !isPending ?
                  (
                    <table className="ludis_table">
                      <thead>
                      <tr className="ludis_table_row">
                        <th className="ludis_table_header_cell">No results found!</th>
                      </tr>
                      </thead>
                    </table>
                  ) :
                  (
                    <table className="ludis_table">
                      <thead className="metrics_header">
                      <tr className="ludis_table_row">
                        <th
                          className="ludis_table_cell"
                        >
                          Name
                        </th>
                        <th
                          className="ludis_table_cell"
                        >
                          Email
                        </th>
                        <th
                          className="ludis_table_cell"
                        >
                          Role
                        </th>
                        <th
                          className="ludis_table_cell center"
                        >
                          2FA Enabled
                        </th>
                        <th
                          className="ludis_table_cell"
                        >
                          Created At
                        </th>
                        <th
                          className="ludis_table_cell"
                        ></th>
                      </tr>
                      </thead>
                      <tbody>
                      {users && users?.map((userEntry, index) => (
                        <tr
                          key={index}
                          className="ludis_table_row"
                        >
                          <td className="ludis_table_cell">
                            {userEntry.first_name} {userEntry.last_name}
                          </td>
                          <td className="ludis_table_cell">
                            {userEntry.email}
                          </td>
                          <td className="ludis_table_cell">
                            {userEntry.role}
                          </td>
                          <td className="ludis_table_cell center">
                            {userEntry.enabled_2fa ?
                              <Check sx={{ color: SUCCESS_COLOR }} /> :
                              <Close sx={{ color: FAILURE_COLOR }} />}
                          </td>
                          <td className="ludis_table_cell">
                <span>
                  {moment(userEntry.created_at).format((user.date_format ? user.date_format : DEFAULT_DATETIME_OPTION), "HH:mm A")}
                </span>
                          </td>
                          <td className="ludis_table_cell">
                            <button
                              className={` ${style.btn_transperent} ${style.ldsToolTips}`}>
                              <div className="show__tool mr-2"
                                   onClick={() => handleOpen(userEntry)}>
                                <i
                                  className={`fa fa-edit ${style.color_primary}`}
                                ></i>{" "}
                                <span className="show_pin_rep">Edit</span>
                              </div>
                            </button>
                            <button
                              className={` ${style.btn_transperent} ${style.ldsToolTips}`}
                              onClick={() => handleDelete(userEntry.uid)}>
                              {" "}
                              <div className="show__tool mr-2">
                                <i className="fa fa-trash text-danger"></i>
                                <span className="show_pin_rep">Delete</span>
                              </div>
                            </button>
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  )
                }
              </>
            )
          }
        </InfiniteScroll>
      </div>
      <div className={style.Divider_light_bottom}>
        <div className={style.Box_root}>
          <div className={style.left_Side}>
            <div className={style.left_Side}>
            <span className={style.result_All}>
              <strong>{userCount}</strong> results
            </span>
            </div>
          </div>
        </div>
      </div>
      <EditUserModal showModal={showEditModal} setShowModal={setShowEditModal}
                     user={selectedUser} selectedOrg={organizationId}
                     handleUserPagination={handleUserPagination}></EditUserModal>
    </>
  );
};
export default UsersTable;

