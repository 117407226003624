import React, { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import { useDispatch } from "react-redux";

const SearchInput = ({ handleSearchTerm, handlePending, clearData, clearTerm = false, handleClearTerm = (s) => {}}) => {
  const [localSearchTerm, setLocalSearchTerm] = useState('')
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearData);
    handlePending(true);
    const debounceFn = setTimeout(() => {
      handleSearchTerm({ page: 1, limit: 10, searchTerm: localSearchTerm.trim().toLowerCase() })
      handlePending(false);
    }, 500);
    return () => {
      clearTimeout(debounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, localSearchTerm])

  useEffect(() => {
    if (clearTerm) {
      setLocalSearchTerm('');
      handleClearTerm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearTerm])

  return (
    <div className="metrics_search_container">
      <Search classes="metrics_search_icon"/>
      <input className="metrics_keyword_input" placeholder="Search" value={localSearchTerm} onChange={(e) => {
        setLocalSearchTerm(e.target.value)}}>
      </input>
    </div>
  )
}

export default SearchInput