export const LLMActionTypes = {
  CREATE_LOG_SUMMARY: "CREATE_LOG_SUMMARY",
  CREATE_LOG_SUMMARY_PENDING: "CREATE_LOG_SUMMARY_PENDING",
  CREATE_LOG_SUMMARY_FULFILLED: "CREATE_LOG_SUMMARY_FULFILLED",

  SEND_AGENT_MESSAGE: "SEND_AGENT_MESSAGE",
  SEND_AGENT_MESSAGE_PENDING: "SEND_AGENT_MESSAGE_PENDING",
  SEND_AGENT_MESSAGE_FULFILLED: "SEND_AGENT_MESSAGE_FULFILLED",

  CLEAR_LOG_SUMMARY: "CLEAR_LOG_SUMMARY",
}