import React, { useState } from 'react';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as commonService from "utility/CommonService.js";

function EmailNotificationForm({isOpen, emails, isOnSuccess, handleSetOnSuccess, isOnFailure, handleSetOnFailure, handleEmailAdd, handleEmailDelete}) {
  const [newEmail, setNewEmail] = useState("");

  if (!isOpen) {
    return null;
  }

  const handleNewEmail = () => {
    if (newEmail.length > 0 && validateEmail(newEmail)) {
      handleEmailAdd(newEmail)
      setNewEmail("")
    } else {
      commonService.forError("Please enter valid email", "Error")
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <div className="email-notification-form">
      <p className="email-notification-header">Email about following statuses:</p>
      <div className="toggle-item">
        <input
          type="checkbox"
          id="success"
          checked={isOnSuccess}
          onChange={handleSetOnSuccess}
        />
        <label htmlFor="success">Success</label>
      </div>
      <div className="toggle-item">
        <input
          type="checkbox"
          id="failure"
          checked={isOnFailure}
          onChange={handleSetOnFailure}
        />
        <label htmlFor="failure">Failure</label>
      </div>
      <div className="emails-table">
        <p className="email-notification-header">Send Email to:</p>
        {emails?.map((email) => (
          <EmailRow
            key={email}
            email={email}
            handleEmailDelete={handleEmailDelete}
          />
        ))}
        <div className="email-row">
          <input
            className="email-notification-input"
            placeholder="Add Email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <div className="email-add-icon">
            <AddCircleOutlineIcon id="add_icn" onClick={handleNewEmail} />
          </div>
        </div>
      </div>
    </div>
  );
};

const EmailRow = ({ email, handleEmailDelete }) => {

  return (
    <div className="email-row">
      <div className="env_var_info">
        <span>
          <p>{email}</p>
        </span>
      </div>
        <HighlightOffIcon
          className="remove-email-icon"
          onClick={() => handleEmailDelete(email)}
        />
    </div>
  );
};

export default EmailNotificationForm;
