import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal } from "react-bootstrap";
import {
  HighlightOff,
  VisibilityOffOutlined,
  VisibilityOutlined
} from "@mui/icons-material";
import { IconButton } from "@material-ui/core";
import {
  Button,
  Input,
  Select
} from "../shared/ui-components/form-components";
import timezones from "google-timezones-json";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from "react-redux";
import { ROLE } from "../../config";
import {
  getActiveUserCounts,
  getOrganization,
  getOrganizations
} from "../../redux/actions/OrganizationActions/Action";
import {
  clearUsers,
  createUser,
  updateUser
} from "../../redux/actions/UserActions/Action";
import * as commonService from "../../utility/CommonService";
import { ROLES } from "../../utility/constants/constants";
import { AsyncPaginate } from 'react-select-async-paginate';
import { UserPaginationContext } from "./AdminNavContainer";
import {
  clearCluster,
  clearClusters,
  getCluster,
  getClusters
} from "../../redux/actions/ClusterActions/Action";
import { handleLoadOptions } from "../../utility/CommonService";

const AddUserModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const { handleClearTerm } = useContext(UserPaginationContext);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [isDisable, setDisable] = useState(false);
  const currentUser = useSelector((state) => state.authReducer.user);

  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "",
    timezone: "",
    organization: currentUser.role === ROLES.ADMINISTRATOR ? currentUser.organization : "",
    cluster_id: currentUser.role === ROLES.ADMINISTRATOR ? currentUser.cluster_id : ""
  });
  const [showPassword, setShowPassword] = useState(false)
  const { configOptions } = useSelector((state) => state.flightingReducer);
  const { user } = useSelector((state) => state.userReducer);
  const {
    organization,
    organizations,
    orgCount
  } = useSelector((state) => state.organizationReducer);
  const {
    cluster,
    clusters,
    clusterCount
  } = useSelector((state) => state.clusterReducer);
  let roles = configOptions.user_roles
    ? configOptions.user_roles.filter(
      (e) =>
        e !== ROLES.SUPERADMIN || currentUser?.role === ROLES.SUPERADMIN
    )
    : ROLE;

  const [orgPagination, setOrgPagination] = useState({
    page: 1,
    limit: 10,
  });

  // Pagination for the clusters dropdown
  const [clustersPagination, setClustersPagination] = useState({
    page: 1,
    limit: 10,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleClose = () => {
    setShowModal(false);
    setForm({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      role: "",
      timezone: "",
      organization: "",
      cluster_id: "",
    })
    simpleValidator.current.hideMessages()
    setOrgPagination({
      page: 1,
      limit: 10,
    });
    setClustersPagination({
      page: 1,
      limit: 10
    });
    dispatch(clearCluster());
    dispatch(clearClusters());
    dispatch(clearUsers());
    dispatch(getActiveUserCounts());
    handleClearTerm(true);
  }

  const handleChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
    if (isDisable) setDisable(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let res;
      if (currentUser.role === ROLES.ADMINISTRATOR) {
        form.organization = currentUser.organization
      }

      if (user) {
        res = await dispatch(
          updateUser({
            body: {
              user: form,
            },
            id: user.uid,
          })
        );
      } else {
        res = await dispatch(
          createUser({
            body: {
              user: form,
            },
          })
        );
      }

      if (res?.value.success) {
        commonService.forSuccess(
          user ? "User Updated successfully!" : " User created successfully!",
          "Success"
        );
        handleClose()
        await dispatch(getOrganization({ id: form.organization }));
        await dispatch(getActiveUserCounts());
      }
    } else {
      simpleValidator.current.showMessages(); //show validation messages
      setDisable(true);
    }
  };

  useEffect(() => {
    if (showModal && currentUser.role === ROLES.SUPERADMIN) {
      dispatch(
        getOrganizations({ ...orgPagination })
      );
    }
  }, [currentUser.role, dispatch, orgPagination, showModal]);

  useEffect(() => {
    if (showModal && currentUser.role === ROLES.ADMINISTRATOR) {
      dispatch(getOrganization({ id: currentUser.organization }));
      dispatch(getCluster(currentUser.cluster_id));
    }
  }, [currentUser.cluster_id, currentUser.organization, currentUser.role, dispatch, showModal])

  useEffect(() => {
    if (showModal && currentUser.role === ROLES.SUPERADMIN) {
      dispatch(
        getClusters({ ...clustersPagination })
      );
    }
  }, [clustersPagination, currentUser.role, dispatch, showModal])

  // This should trigger when a superadmin changes the cluster in the dropdown
  useEffect(() => {
    if (form.cluster_id) {
      dispatch(getCluster(form.cluster_id))
    }
  }, [dispatch, form.cluster_id])

  useEffect(() => {
    if (showModal && currentUser.role === ROLES.ADMINISTRATOR) {
      setForm({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role: "",
        timezone: "",
        organization: currentUser.organization,
        cluster_id: currentUser.cluster_id
      })
    }
  }, [currentUser.cluster_id, currentUser.organization, currentUser.role, showModal])

  return (
    <Modal show={showModal}
           onHide={handleClose}
           animation={false}>
      <Modal.Header className="border-0 modal_header">
        <div className="header_text">Add users</div>
        <IconButton onClick={handleClose} aria-label="Close">
          <HighlightOff></HighlightOff>
        </IconButton>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="add_user_modal_body">
            <div className="field_wrapper">
              <>
                <div className="add_user_field_header">
                  Organization
                </div>
                <AsyncPaginate
                  placeholder={currentUser.role !== ROLES.SUPERADMIN && organization ? organization.name : "select the organization"}
                  name="organization"
                  onChange={(e) => {
                    handleChange({
                      target: { value: e.value, name: "organization" },
                    })
                  }}
                  className="add_user_select"
                  required
                  isDisabled={currentUser.role !== ROLES.SUPERADMIN}
                  loadOptions={handleLoadOptions(organizations, orgPagination, setOrgPagination, orgCount)}
                />
              </>
              {currentUser.role === ROLES.SUPERADMIN && simpleValidator.current.message(
                "organization",
                form.organization,
                "required"
              )}
            </div>
            <div className="field_wrapper">
              <>
                <div className="add_user_field_header">
                  Cluster
                </div>
                <AsyncPaginate
                  placeholder={cluster ? (cluster.name ? cluster.name : cluster.id) : "select the cluster"}
                  name="cluster"
                  onChange={(e) => {
                    handleChange({
                      target: { value: e.value, name: "cluster_id" },
                    })
                  }}
                  className="add_user_select"
                  isDisabled={currentUser.role !== ROLES.SUPERADMIN}
                  loadOptions={handleLoadOptions(clusters, clustersPagination, setClustersPagination, clusterCount)}
                />
              </>
            </div>
            {simpleValidator.current.message(
              "cluster_id",
              form.cluster_id,
              "required"
            )}
            <div className="field_wrapper">
              <div className="add_user_field_header">
                Email
              </div>
              <Input
                type="text"
                name="email"
                placeholder="enter email address"
                onChange={handleChange}
                value={form.email}
                className="add_user_field"
              />{" "}
              {simpleValidator.current.message(
                "email",
                form.email,
                "required|email"
              )}
            </div>
            <div className="field_wrapper">
              <div className="add_user_field_header">
                First Name
              </div>
              <Input
                type="text"
                name="first_name"
                placeholder="enter first name"
                value={form.first_name}
                onChange={handleChange}
                className="add_user_field"
              />
              {simpleValidator.current.message(
                "first_name",
                form.first_name,
                "required"
              )}
            </div>
            <div className="field_wrapper">
              <div className="add_user_field_header">
                Last Name
              </div>
              <Input
                type="text"
                name="last_name"
                placeholder="enter last name"
                value={form.last_name}
                onChange={handleChange}
                className="add_user_field"
              />
              {simpleValidator.current.message(
                "last_name",
                form.last_name,
                "required"
              )}
            </div>
            <div className="field_wrapper">
              <div className="add_user_field_header">
                Role
              </div>
              <Select
                placeholder="select role"
                name="role"
                options={roles}
                onChange={(e) =>
                  handleChange({
                    target: { value: e.value, name: "role" },
                  })
                }
                value={{
                  label: form.role,
                  value: form.role,
                }}
                className="add_user_select"
                required
              />
              {simpleValidator.current.message("role", form.role, "required")}
            </div>
            <div className="field_wrapper">
              <div className="add_user_field_header">
                Time Zone
              </div>
              <Select
                options={Object.keys(timezones)}
                placeholder="select the time zone"
                name="timezone"
                onChange={(e) =>
                  handleChange({
                    target: { value: e.value, name: "timezone" },
                  })
                }
                value={{
                  label: form.timezone,
                  value: form.timezone,
                }}
                className="add_user_select"
                required
              />
              {simpleValidator.current.message(
                "timezone",
                form.timezone,
                "required"
              )}
            </div>
            <div className="field_wrapper">
              <div className="add_user_field_header">
                Password
              </div>
              <div className="add_user_password_field">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="enter password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  className="add_user_password_field_input"
                />
                <div className="password_field_eye_icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOutlined></VisibilityOutlined> : <VisibilityOffOutlined></VisibilityOffOutlined>}
                </div>
              </div>
              {simpleValidator.current.message(
                "password",
                form.password,
                "required"
              )}
            </div>
            <div className="add_user_save_button_container">
              <Button type="submit" className="add_user_save_button btn"
                      disabled={isDisable}>
                Save
              </Button>
            </div>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  )
}

export default AddUserModal