import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/ProjectsActions";
import * as appActions from "redux/actions/AppActions";
import * as llmActions from "redux/actions/LLMActions";
import PublishModal from "components/ProjectsV2/ProjectDetails/PublishModal";
import CreateWorkflowModal from "components/Workflows/WorkflowDetails/CreateWorkflowModal";
import FileNavigator from "./FileNavigator";
import ChatInterface from "components/shared/ChatBox";
import DevTools from "./DevTools";
import Header from "./Header";
import { LANGUAGE_OPTIONS } from "utility/constants/constants";
import CommitModal from "components/ProjectsV2/ProjectDetails/CommitModal";
import ProjectPublishModal from "./ProjectPublishModal";
import WorkflowPublishModal from "./WorkflowPublishModal";
import CodeEditor from "./CodeEditor";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as commonService from "utility/CommonService.js";
import {
  checkHasUnsavedChanges,
  confirmUnsavedChanges,
  getFileNameFromPath,
  getPreviousFilePath,
  getPreviousParentFolder,
  pathIsFileType,
} from "utility/utility";

export const ProjectSummaryClickedContext = React.createContext(null);

const ProjectIDE = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { project, currentFilePath, repoContents, projectFilesContent } = useSelector(
    (state) => state.projectsReducer
  );

  let projectID = history.location.pathname.split("/")[2];
  const [showCommitModal, setShowCommitModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showCreateWorkflowModal, setShowCreateWorkflowModal] = useState(false);
  const [languageSelection, setLanguageSelection] = useState("text");
  const [showEnvVars, setShowEnvVars] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [showTerminal, setShowTerminal] = useState(false);
  const [fullScreen, setFullScreen] = useState(true);
  const [showProjectPublishModal, setShowProjectPublishModal] = useState(false);
  const [showWorkflowPublishModal, setShowWorkflowPublishModal] = useState(false);
  const [accessLevel, setAccessLevel] = useState("edit");
  const [summaryClicked, setSummaryClicked] = useState(false);
  const [showSummaryButton, setShowSummaryButton] = useState(false)
  const [showAI, setShowAI] = useState(!showLogs && !showEnvVars && !showTerminal);
  const currentFile = getFileNameFromPath(currentFilePath);

  const handleShowPublishModal = () => {
    setShowPublishModal(true)
    setShowWorkflowPublishModal(false)
    setShowProjectPublishModal(false)
  }
  const handleShowProjectPublishModal = () => {
    setShowProjectPublishModal(!showProjectPublishModal)
    setShowPublishModal(false)
  };
  const handleShowWorkflowPublishModal = () => {
    setShowWorkflowPublishModal(!showWorkflowPublishModal)
    setShowPublishModal(false)
  };
  const handleShowCreateWorkflowModal = () => {
    setShowCreateWorkflowModal(!showCreateWorkflowModal)
  }

  const handleSummaryClicked = (value) => {
    setSummaryClicked(value)
  }

  const handleShowSummaryButton = (value) => {
    setShowSummaryButton(value)
  }

  const startContainer = async () => {
    await dispatch(llmActions.clearLogSummary());

    handleSummaryClicked(false);
    const form = {
      name: project.name,
      language: currentFile.toLowerCase().endsWith(".r") ? "r" : "python",
      project_id: project.id,
      publish_filenames: [currentFile],
      isAppPreview: true,
    };

    await dispatch(
      appActions.createAppPreview({
        ...form,
      })
    )
    handleShowSummaryButton(false);
  };

  useEffect(() => {
    dispatch(actions.getProject({ id: projectID })).then((res) =>
    {
      setAccessLevel(res.value.project.access_level)
    });
  }, [dispatch, projectID]);

  const handleSetFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const autoSetLanguage = (currentFile) => {
    let ext = currentFile.split(".").pop().toLowerCase();
    if (ext === "py") setLanguageSelection(LANGUAGE_OPTIONS.PYTHON);
    else if (ext === "r") setLanguageSelection(LANGUAGE_OPTIONS.R);
    else if (ext === "js") setLanguageSelection(LANGUAGE_OPTIONS.JAVASCRIPT);
    else setLanguageSelection(LANGUAGE_OPTIONS.TEXT);
  };

  // hide AI chat button if side panels are open
  useEffect(() => {
    setShowAI(!showLogs && !showEnvVars && !showTerminal);
  }, [showLogs, showEnvVars, showTerminal])

  useEffect(() => {
    autoSetLanguage(currentFile);
  }, [currentFile]);

  const handleShowCommitModal = () => {
    setShowCommitModal(!showCommitModal);
  };

  const onCommit = async (newFileName) => {
    let pathIsFile = pathIsFileType(currentFilePath, repoContents);
    let currentFolder = pathIsFile ? getPreviousFilePath(currentFilePath) : currentFilePath;
    let filePath;
    if (newFileName) {
      filePath = `${currentFolder}${
        currentFolder ? `/${newFileName}` : newFileName
      }`;
      if (checkHasUnsavedChanges(projectFilesContent)) {
        let res = await confirmUnsavedChanges()
        if (!res)
          return handleShowCommitModal()
      }
    } else {
      filePath = currentFilePath;
    }
    dispatch(
      actions.commitFileContent({
        body: {
          project: project,
          filePath: filePath,
          contents: showCommitModal ? "" : projectFilesContent[filePath].code,
          message: `${newFileName || currentFile} commit`,
        },
      })
    ).then(() => {
      commonService.forSuccess("Commit successful", "Success")
      if (showCommitModal) {
        handleShowCommitModal();
      }
      if (newFileName) {
        dispatch(
          actions.getRepoContent({
            body: {
              project: project,
              path: getPreviousFilePath(filePath),
            },
            id: project.id
          })
        );
      }
    });
  };

  const goBack = () => {
    let previousPath = getPreviousParentFolder(currentFilePath, repoContents);
    dispatch(
      actions.getRepoContent({ body: { project: project, path: previousPath }, id: project.id })
    );
  };

  const handleShowEnvVariables = () => {
    setShowEnvVars(!showEnvVars);
  };

  const handleShowLogs = (showLogs) => {
    if (showLogs !== undefined)
      setShowLogs(showLogs)
    else
      setShowLogs(!showLogs);
  };

  const handleShowTerminal = useCallback(() => {
    setShowTerminal((prev) => !prev);
  }, [])

  const disableIcon = (accessLevelsToDisable, userAccessLevel) => {
    return `toolbar_icn${accessLevelsToDisable.includes(userAccessLevel) ? "_disabled" : ""}`
  }

  return (
    project &&
      <ProjectSummaryClickedContext.Provider value={{
        summaryClicked,
        showSummaryButton,
        handleSummaryClicked,
        handleShowSummaryButton
      }}>
        <div className={`project_details_wrapper ${fullScreen ? "expanded" : ""}`}>
          <Header
            setShowPublishModal={setShowPublishModal}
            setShowCreateWorkflowModal={setShowCreateWorkflowModal}
            accessLevel={accessLevel}
            disableIcon={disableIcon}
          />
          <div className="code_area_wrapper">
            <FileNavigator
              goBack={goBack}
              handleShowCommitModal={handleShowCommitModal}
              accessLevel={accessLevel}
              disableIcon={disableIcon}
              setShowCreateWorkflowModal={setShowCreateWorkflowModal}
            />
            <div className="code_and_tools_wrapper">
              <div
                className={`ace_editor_wrapper ${
                  (showEnvVars || showLogs) && "half"
                }`}
              >
                <CodeEditor
                  startContainer={startContainer}
                  languageSelection={languageSelection}
                  handleShowEnvVariables={handleShowEnvVariables}
                  handleShowLogs={handleShowLogs}
                  handleShowTerminal={handleShowTerminal}
                  onCommit={onCommit}
                  handleSetFullScreen={handleSetFullScreen}
                  fullScreen={fullScreen}
                  accessLevel={accessLevel}
                  disableIcon={disableIcon}
                />
              </div>
              {(showEnvVars || showLogs || showTerminal) &&
                <DevTools
                  projectId={projectID}
                  handleShowEnvVariables={handleShowEnvVariables}
                  handleShowLogs={handleShowLogs}
                  showLogs={showLogs}
                  showEnvVars={showEnvVars}
                  showTerminal={showTerminal}
                  handleShowTerminal={handleShowTerminal}
                />
              }
            </div>
          </div>
          <CommitModal
            handleShowCommitModal={handleShowCommitModal}
            onCommit={onCommit}
            showCommitModal={showCommitModal}
          />
          {showPublishModal && <PublishModal
            handleShowProjectPublishModal={handleShowProjectPublishModal}
            handleShowWorkflowPublishModal={handleShowWorkflowPublishModal}
            show={showPublishModal}
            setShowPublishModal={setShowPublishModal}/>}
          <ProjectPublishModal
            projectID={projectID}
            show={showProjectPublishModal}
            setShowProjectPublishModal={handleShowProjectPublishModal}
            setShowPublishModal={handleShowPublishModal}
          />
          {showWorkflowPublishModal && <WorkflowPublishModal
            projectID={projectID}
            show={showWorkflowPublishModal}
            setShowWorkflowPublishModal={handleShowWorkflowPublishModal}
            setShowCreateWorkflowModal={handleShowCreateWorkflowModal}
            setShowPublishModal={handleShowPublishModal}
          />}
          <CreateWorkflowModal
            show={showCreateWorkflowModal}
            setShowCreateWorkflowModal={handleShowCreateWorkflowModal}
            setShowWorkflowPublishModal={handleShowWorkflowPublishModal}
          />
          <div className={showAI ? 'chat-visible' : 'chat-hidden'}>
            <ChatInterface projectId={projectID} />
          </div>
        </div>
      </ProjectSummaryClickedContext.Provider>
  );
};

export default ProjectIDE;
