export const PASSWORD_MIN_LEN = 6;
export const DEFAULT_DATETIME_OPTION = "MM/DD/YYYY";
export const DEFAULT_TIME_FORMAT = "HH:mm";
export const routes = {
  ROOT: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot_password",
  RESET_PASSWORD: "/resetpassword",
  CHANGE_PASSWORD: "/changepassword",
  HOME: "/home",
  PROFILE_SETTING: "/profile-setting",
  PROJECT_LIST: "/projects",
  PROJECT_V2_LIST: "/projectsv2",
  ADD_PROJECT: "/projects/new",
  EDIT_PROJECT: "/projects/:id/edit",
  PROJECT_DETAILS: "/projects/:id",
  PROJECT_DETAILS_V2: "/projectsv2/:id",
  ADD_NOTE_BOOKS: "/add-notebooks",
  IMPORT_NOTE_BOOKS: "/import-notebooks",
  ADD_TEMPLATE: "/add-template",
  PREVIEW_NOTE_BOOKS: "/notebooks/:id/preview",
  EDIT_NOTE_BOOKS: "/notebooks/:id/edit",
  NOTE_BOOKS_DETAIL: "/notebooks/:id",
  INSIGHTS_LIST: "/insights",
  DATASETS_LIST: "/datasets",
  ADD_DATASET: "/datasets/new",
  EDIT_DATASET: "/datasets/edit/:id",
  DATASET_DETAILS: "/datasets/:id",
  MARKETPLACE: "/marketplace",
  DASHBOARD: "/dashboard",
  APP_DETAILS: "/app/:id",
  USERS: "/organizations/:organizationId/users",
  CREATE_USER: "/organizations/:organizationId/users/new",
  EDIT_USER: "/organizations/:organizationId/users/edit/:userId",
  ORGANIZATIONS: "/organizations",
  CREATE_ORGANIZATIONS: "/organizations/new",
  MANAGE_ORGANIZATION: "/manage-organizations",
  EDIT_ORGANIZATIONS: "/organizations/:organizationId",
  FLIGHT_CONFIG: '/flight-settings',
  FLIGHT_CONFIG_ORGANIZATION: "/organizations/:organizationId/flight-settings",
  CRYPTO: "/crypto",
  COLLECTIONS: "/collections",
  ADD_COLLECTIONS: "/collections/new",
  TWO_FACTOR_AUTH_ENABLE: "/two-factor-auth-enable",
  TWO_FACTOR_AUTH_VERIFY: "/two-factor-auth-verify",
  WORKFLOWS: "/workflows",
  WORKFLOW_DETAILS: "/workflows/:id",
  ADMIN_PORTAL: "/admin-portal",
  VISUAL_WORKFLOWS: "/visual-workflows/:id",
};

export const LOCATIONS = {
  WORKFLOWS: "workflows",
  PROJECTS: "projectsv2"
}

export const FLIGHTING_CONSTANTS = {
  INSIGHTS: "insights",
  MARKETPLACE: "marketplace",
  PROJECTS: "projects",
  DATASETS: "datasets",
  HOME: "home",
  FAKE: "fake",
  WORKFLOWS: "workflows",
  NOTEBOOKS: "notebooks",
};

export const PAGE_SIZE_CONSTANTS = {
  REPORTS: 8,
  ROWS: 5,
  APPS: 1000,
  PROJECTS: 12,
  DATASETS: 30,
  HOME_INSIGHTS_PREVIEW: 3,
  PROJECT_DATASETS_MAX: 10000,
  WORKFLOWS: 10
}

export const SORT_OPTIONS = ["name", "created_at", "updated_at"]

export const ENTITY_TYPES = {
  APPLICATION: "Application",
  PROJECT: "Project",
  PROJECT_V2: "Project_V2",
  DATASET: "Dataset",
  WORKFLOW: "Workflow",
  INSIGHT: "Insight",
  DESIGNER: "Designer"
}

export const SHARE_TYPE = {
  SHARE: "share",
  UNSHARE: "unshare"
}

export const TO_ENTITY_TYPE = {
  USER: "User",
  ORGANIZATION: "Organization"
}

export const ACCESS_LEVEL = {
  VIEW: "view",
  EXECUTE: "execute",
  EDIT: "edit",
  NONE: "none",
  ALL_EXECUTE: ["execute", "edit"]
}

export const ACCESS_LEVELS = ["none","view", "execute", "edit"]

export const APP_STATUS_CONSTANTS = {
  RUNNING: "running",
  EXTERNAL: "external",
  DNE: "dne"
}

export const AWS_CONSTANTS = {
  AMPLIFY_PREFIX: "public",
  REPORTS_S3_PATH: "Reports",
  USER_IMG_PATH: "user asset images",
  GET_OBJECT: "GetObject",
}

export const INSIGHTS_TABS = {
  DASHBOARD: "Dashboard",
  REPORTS: "Reports",
  APPLICATIONS: "Applications",
  SHARED_WITH_ME: "Shared With Me"
}

export const PROJECT_TABS = {
  MY_PROJECTS: "myProjects",
  SHARED: "shared",
}

export const DATASET_TABS = {
  MY_DATASETS: "myDatasets",
  SHARED: "shared",
  COLLECTIONS: "collections"
}

export const DISPLAY_COMPONENT = {
  ACTIVE: "active",
  FADE: "fade",
}

export const ACCENT_COLORS = [
  "#F6CC0D",
  "#67BF9D",
  "#509BFD",
  "#42D0D0"
]

export const ENTITY_COLORS = {
  DATASET: ACCENT_COLORS[0],
  INSIGHT: ACCENT_COLORS[1],
  PROJECT_V2: ACCENT_COLORS[2],
  WORKFLOW: ACCENT_COLORS[3]
}

export const LANGUAGE_OPTIONS = {
  PYTHON: "python",
  R: 'r',
  JAVASCRIPT: 'javascript',
  TEXT: 'text',
}

export const APP_TEMPLATES = {
	CLUSTER: "cluster",
	SLICEANDDICE: "slice-and-dice",
  CAMPAIGN:"campaign"
};

export const APP_TEMPLATES_IMAGES = [
	{
    img_path: "assets/custom-styles/images/templates/clustering.png",
    img: require(`assets/custom-styles/images/templates/clustering.png`),
		title: APP_TEMPLATES.CLUSTER,
		author: "Ludis",
	},
	{
    img_path: "assets/custom-styles/images/templates/sliceAndDice.png",
    img: require("assets/custom-styles/images/templates/sliceAndDice.png"),
		title: APP_TEMPLATES.SLICEANDDICE,
		author: "Ludis",
  },
  {
    img_path: "assets/custom-styles/images/templates/campaign.png",
    img: require("assets/custom-styles/images/templates/campaign.png"),
		title: APP_TEMPLATES.CAMPAIGN,
		author: "Ludis",
  },
];

export const CAMPAIGN_APP_COLORS = {
  kColor: ["rgba(247, 101, 96,",
            "rgba(255, 154, 46,",
            "rgba(251, 232, 66,",
            "rgba(181, 226, 65,",
            "rgba(35, 195, 67,",
            "rgba(55, 212, 207,",
            "rgba(87, 169, 251,",
            "rgba(141, 78, 218,",
            "rgba(247, 84, 168,",
            "rgba(181, 226, 65,",
            "rgba(249, 204, 69,",
            "rgba(255, 154, 46,"],
  fadeColor: "rgba(255, 255, 255, .2)",
  clusterBackground: 'rgba(197, 197, 219, 0.25)',
}

export const dateFormats =  [
  'HH:mm A',
  'HH:mm',
  'D MMMM YYYY',
  'D MMMM YYYY HH:mm',
  'DD-MM-YY',
  'DD-MM-YYYY',
  'DD.MM.YYYY',
  'DD.MM.YYYY HH:mm',
  'DD/MM/YY',
  'DD/MM/YYYY',
  'DD/MM/YYYY HH:mm:ss',
  'HH:mm:ss',
  'M/D/YYYY',
  'D/M/YYYY',
  'MM-DD-YY',
  'MM-DD-YYYY',
  'MM-DD-YYYY HH:mm:ss',
  'MM/DD/YY',
  'MM/DD/YYYY',
  'MM/DD/YYYY HH:mm:ss',
  'MMM D YYYY',
  'MMM D YYYY LT',
  'MMMM Do YYYY',
  'MMMM Do YYYY LT',
  'YYYY-DD-MM HH:mm:ss',
  'YYYY-MM',
  'YYYY-MM-DD',
  'YYYY-MM-DD HH:mm',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY-MM-DD LT',
  'YYYY-MM-DD h:mm:ss A',
  'YYYY-MM-DDTHH:mm:ssZ',
  'ddd, MMM D YYYY LT',
  'dddd D MMMM YYYY HH:mm',
  'dddd, MMMM Do YYYY LT'
]

export const PUBLISH_TYPES = {
  INSIGHT: 'Insight',
  WORKFLOW: 'Workflow'
}

export const WORKFLOW_VARIABLES = {
  LUDIS_SCHEDULE: 'LUDIS_SCHEDULE_ID'
}

export const TASK_COLORS = {
  FAILED: '#FF7E86',
  UPSTREAM_FAILED: '#FFA500',
  SUCCESS: '#4EBE8F',
  DEFAULT: 'gray',
  SELECTED: '#E4E7FF',
  CLEAR: '#FFFFFF'
}

export const TASK_BORDER = {
  SELECTED:  "1px solid indigo",
  CLEAR: 0
}

export const INSIGHT_FILTERS = {
  'all apps':'allApps',
  'starred':'pinned',
  'created by me':'owned',
  'shared with me':'shared',
  'template':'template'
}

export const DEFAULT_INSIGHT_OPTIONS = {
  page: 1,
  sortOperator: "DESC",
  orderBy: "created_at",
  pageSize: 12,
  filter: 'all apps',
  search: ""
}

export const SUCCESS_COLOR = '#4EBE8F'
export const FAILURE_COLOR = '#FF7E86'
export const LINE_GRAPH_COLORS = [
  "#23C343", "#37D4CF", "#57A9FB", "#8D4EDA", "#E13EDB", "#F754A8",
  "#F76560", "#F99057", "#FF9A2E", "#F9CC45", "#FBE842", "#B5E241",
]
export const BAR_GRAPH_COLORS = [
  "#6191FE", "#42D0D0", "#F9CD5E", "#B398FF", "#D04242"
]

export const ROLES = {
  DEVELOPER: "developer",
  MANAGEMENT: "management",
  ADMINISTRATOR: "administrator",
  SUPERADMIN: "superadmin"
}


export const HEADER_COMPONENT_COLORS = {
  MAIN:"#6B71FB",
  BACKGROUND:"#C1C4FF"
}

export const VISUAL_WORKFLOW_RMD_FILE_OPTIONS = [
  ".pdf",
  ".html"
];

export const TASK_STATE = [
  "success",
  "failed"
]

export const TASK_PAGE_SIZE = 10