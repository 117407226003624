import React, { useEffect, useState } from 'react';
import {
  dropMessages,
  renderCustomComponent,
  toggleMsgLoader,
  Widget
} from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import { useDispatch } from 'react-redux';
import * as llmActions from "../../../redux/actions/LLMActions";
import * as commonService from "../../../utility/CommonService";
import aiProfilePic from "../../../assets/custom-styles/images/icons/aiProfile.svg";
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { capitalizeFirstLetter } from "../../../utility/utility";
import { ContentCopy } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';


const ChatResponseBubble = ({text}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async (text) => {
    setIsCopied(true);
    await navigator.clipboard.writeText(text);
    setTimeout(() => setIsCopied(false), 700);
  }

  return (
    <div className={'rcw-response'}>
      <div className={'rcw-message-text'}>
        <Markdown
          children={text}
          components={{
            code: ({node, inline, className, children, ...props}) => {
              const match = /language-(\w+)/.exec(className || '')
              return !inline && match ? (
                <div className="chat-code-block">
                  <div className="chat-code-block-header">
                    <div className="code-block-language">{capitalizeFirstLetter(match[1])}</div>
                    <button className="code-copy-button" onClick={async () => {
                      await handleCopy(children)
                    }}>
                      <div className="copy-button-text">
                        <SvgIcon className="copy-icon">
                          <ContentCopy></ContentCopy>
                        </SvgIcon>
                        {isCopied ? "Copied": "Copy Code"}
                      </div>
                    </button>
                  </div>
                  <SyntaxHighlighter
                    style={prism}
                    customStyle={{
                      padding: "6px 6px",
                      background: "#F9F9F9",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      marginTop: 0,
                      marginBottom: 0
                    }}
                    children={String(children).replace(/\n$/, '')}
                    language={match[1]}
                    {...props}
                  />
                </div>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              )
            },
          }}
        >
        </Markdown>
      </div>
    </div>
  )
}

const ChatInterface = ({projectId}) => {
  const [messages, setMessages] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dropMessages()
    setMessages([])
    renderCustomComponent(ChatResponseBubble, {text: "Welcome! Start a conversation with the AI assistant."}, true);
  }, [])

  const handleNewUserMessage = (newMessage) => {
    const userMessage = {
      id: Date.now(),
      content: newMessage,
      role: 'user',
      timestamp: new Date().toLocaleTimeString(),
    };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    getAiResponse(updatedMessages);
  };

  const getAiResponse = async (messages) => {
    toggleMsgLoader()
    dispatch(llmActions.sendAgentMessage(projectId, {
      messages: messages,
    })).then((res)=> {
      toggleMsgLoader();
      if (!res?.value?.success) {
        commonService.forError("Could not connect to Ludis AI.", "Error");
      } else {
        const aiMessage = {
          id: Date.now(),
          content: res.value.response,
          role: 'assistant',
          timestamp: new Date().toLocaleTimeString(),
        };
        setMessages((prev) => [...prev, aiMessage]);
        renderCustomComponent(ChatResponseBubble, {text: aiMessage.content}, true)
      }
    })
  };

  return (
    <div className="chat-interface">
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title="Ludis AI Data Scientist"
        subtitle="Start chatting with the AI!"
        profileAvatar={aiProfilePic}
      />
    </div>
  );
};

export default ChatInterface;
