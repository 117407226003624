import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMetricsDetails, getMetricsDetails } from "../../../redux/actions/MetricsActions/Action";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment-timezone";
import { DEFAULT_DATETIME_OPTION } from "../../../utility/constants/constants";
import { capitalizeFirstLetter } from "../../../utility/utility";
import SearchInput from "../../shared/Search/SearchInput";
import Loader from "../../shared/Loader/Loader";

const MetricsTable = ({ organizationId, entity, timeRange }) => {
  const dispatch = useDispatch();
  const initialFetchDone = useRef(false);
  const user = useSelector((state) => state.authReducer.user);
  const metricsDetails = useSelector((state) => state.metricsReducer.metricsDetails);
  const metricsDetailsCount = useSelector((state) => state.metricsReducer.metricsDetailsCount);
  const [metricsPagination, setMetricsPagination] = useState({
    page: 1,
    limit: 10,
    searchTerm: ''
  });
  const [isLoading, setIsLoading] = useState(false); // This is used as the load state for when infinite scroll needs to load more data
  const [isPending, setIsPending] = useState(false); // This is used to account for the debounce from searching

  const handleMetricsPagination = (newPagination) => {
    setMetricsPagination({
      page: newPagination.page,
      limit: newPagination.limit,
      searchTerm: newPagination.searchTerm
    })
  }

  const handleEntityHeader = (entity) => {
    if (entity === "applications") {
      return "Insights"
    } else {
      return capitalizeFirstLetter(entity)
    }
  }

  const handlePending = (pendingStatus) => {
    setIsPending(pendingStatus)
  }

  useEffect(() => {
    dispatch(clearMetricsDetails());
    if (initialFetchDone.current) {
      handleMetricsPagination({
        page: 1,
        limit: 10,
        searchTerm: '',
      })
    } else if (!initialFetchDone.current) {
      initialFetchDone.current = true
    }
  }, [dispatch, timeRange])

  useEffect(() => {
    dispatch(getMetricsDetails(entity, {
      period: timeRange,
      page: metricsPagination.page,
      limit: metricsPagination.limit,
      organizationId: organizationId,
      searchTerm: metricsPagination.searchTerm.trim().toLowerCase()
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, entity, metricsPagination])

  useEffect(() => {
    setIsLoading(false);
  }, [metricsDetails])

  return (
    <>
      <SearchInput handleSearchTerm={handleMetricsPagination} handlePending={handlePending} clearData={clearMetricsDetails()} />
      <div id="scrollableDiv" className="infinite_scroll_container">
        <InfiniteScroll
          dataLength={metricsDetails.length}
          next={() => {
            setIsLoading(true);
            handleMetricsPagination({ ...metricsPagination, page: metricsPagination.page + 1 })
          }}
          hasMore={metricsDetails.length < parseInt(metricsDetailsCount)}
          loader={<Loader loading={isLoading} />}
          scrollableTarget="scrollableDiv"
        >
          {
            isPending ? (
              <table className="ludis_table">
                <thead>
                <tr className="ludis_table_row">
                  <th className="ludis_table_header_cell">Searching...</th>
                </tr>
                </thead>
              </table>
            ) : (
              <>
                { !isPending && metricsDetailsCount === 0 ?
                  (
                    <table className="ludis_table">
                      <thead>
                      <tr className="ludis_table_row">
                        <th className="ludis_table_header_cell">No results found!</th>
                      </tr>
                      </thead>
                    </table>
                  ) :
                  (
                    <table className="ludis_table">
                      <thead className="metrics_table_header">
                      <tr className="ludis_table_row">
                        <th className="ludis_table_header_cell">{handleEntityHeader(entity)}</th>
                        <th className="ludis_table_header_cell">Name</th>
                        <th className="ludis_table_header_cell">Email</th>
                        <th className="ludis_table_header_cell">Action</th>
                        <th className="ludis_table_header_cell">Date</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        metricsDetails && metricsDetails?.map((detail, index) =>
                          (
                            <tr key={index} className="ludis_table_row">
                              <td className="ludis_table_cell">
                                {detail.entity_name}
                              </td>
                              <td className="ludis_table_cell">
                                {detail.first_name} {detail.last_name}
                              </td>
                              <td className="ludis_table_cell">
                                {detail.email}
                              </td>
                              <td className="ludis_table_cell">
                                {detail.action}
                              </td>
                              <td className="ludis_table_cell">
                                {moment(detail.date).format(user.date_format ? `${user.date_format} HH:mm:ss`: `${DEFAULT_DATETIME_OPTION} HH:mm:ss`)}
                              </td>
                            </tr>
                          ))
                      }
                      </tbody>
                    </table>
                  )
                }
              </>
            )
          }
        </InfiniteScroll>
      </div>
    </>
  )
}

export default MetricsTable